// TempData
import TempDataNavBar from '../TempData_NavBar';
import TempDataMegaMenu from '../TempData_MegaMenu';
import TempDataFooter from '../TempData_footer';

import Image from '../../../assets/Images/InfoCard3.png';

// Page Data
const Data = {
  pageName: 'InfoCard Test Page',
  pageTemplate: 'Template3',
  pageData: {
    nav: TempDataNavBar,
    megaMenu: TempDataMegaMenu,
    footer: TempDataFooter,
    level_1_Id: 'Page_1',
    pageTitle: 'HTML render test page',
    pageDescription: 'Tester component - HTML render',
    bannerImage: null, // accepts null
    bannerContent: '<h1>Banner text accepts HTML</h1>', // accepts null
    bottomContent: [],
    desktopTools: {}, // accepts null
  },
  pageContent: [
    {
      componentName: 'InfoCard',
      componentData: {
        id: 1,
        image: Image,
        imageAltText: 'image alt text',
        category: 'pension',
        text:
          '<h3>Få overblik over din pension</h3>'
          + '<p>Dan dig nemt og hurtigt et overblik over din pension og se hvor godt du er stillet i forhold til din pension</p>',
        url: '/item1',
      },
    },
    {
      componentName: 'InfoCard',
      componentData: {
        id: 1,
        image: '',
        imageAltText: 'image alt text',
        category: 'pension',
        text:
          '<h3>Få overblik over din pension</h3>'
          + '<p>Dan dig nemt og hurtigt et overblik over din pension og se hvor godt du er stillet i forhold til din pension</p>',
        url: '/item1',
      },
    },
    {
      componentName: 'Columns',
      componentData: {
        columns: 3,
        gap: '40',
        minWidth: '762px',
      },
      componentChildren: [
        {
          componentName: 'InfoCard',
          componentData: {
            image: '',
            imageAltText: '',
            category: '',
            url: '/subsites/sportmaster/sportmaster-butiksansatte',
            text:
              '<h3>Butiksansatte</h3><p>Læs om pensionsordningen for butiksansatte her.</p>',
          },
        },
        {
          componentName: 'InfoCard',
          componentData: {
            image: '',
            imageAltText: '',
            category: '',
            url: '/subsites/sportmaster/butiksansatte-timeloennede',
            text:
              '<h3>Butiksansatte på timeløn</h3><p>Læs om pensionsordningen for butiksansatte på timeløn her.</p>',
          },
        },
        {
          componentName: 'InfoCard',
          componentData: {
            image: '',
            imageAltText: '',
            category: '',
            url: '/subsites/sportmaster/butiksansatte-timeloennede',
            text:
              '<h3>Butiksansatte på timeløn</h3><p>Læs om pensionsordningen for butiksansatte på timeløn her.</p>',
          },
        },
      ],
    },
  ],
};

export default Data;
